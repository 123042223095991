import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Chaque année, 180 000 tonnes d’emballages sont produits en France dans le secteur de la restauration rapide. `}</p>
    <p>{`L’objectif  : `}<strong parentName="p">{`atteindre 0 plastique jetable d’ici 2040`}</strong>{` ! `}</p>
    <p>{`Mais comment y arriver? C’est le principe de la loi AGEC.`}</p>
    <h3><strong parentName="h3">{`Qu’est ce que la Loi AGEC ?`}</strong>{` `}</h3>
    <p>{`C'est la `}<strong parentName="p">{`loi anti-gaspillage pour une économie circulaire`}</strong>{`, promulguée en février 2020.  Nous sommes tous concernés, restaurateurs, entreprises et citoyens. Pour atteindre le 0 plastique jetable d’ici 2040, la loi instaure des réglementations étape par étape ! `}</p>
    <p>{`Zoomons sur… la restauration !`}</p>
    <h3><strong parentName="h3">{`Qu’est ce qui va changer pour la vente à emporter ?`}</strong>{` `}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`2021: interdiction de vente de gobelet, coton-tige, assiette en plastique, paille,`}</strong>{` touillette, boîte en polystyrène (type kebab), couvercle et bouchons pour boissons`}</li>
      <li parentName="ul">{`2030: réduction de 50% du gaspillage alimentaire par rapport à 2015 au niveau de la restauration commerciale`}</li>
      <li parentName="ul">{`2021: chaque consommateur peut apporter son récipient dans les commerces de vente au détail`}</li>
      <li parentName="ul"><strong parentName="li">{`2022:  obligation de réemploi de couverts, assiettes et récipients utilisés`}</strong></li>
    </ul>
    <h3><strong parentName="h3">{`Qu’est ce qui va changer pour la livraison de repas ?`}</strong></h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`2021: Fin de la vente systématique de sauces et de couverts en plastique`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`2022: 50% des emballages livrés seront sans plastiques à usage unique`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`2023 : fin de la vente de sac plastique`}</strong>{` et  70% des emballages livrés seront sans plastique à usage unique`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`100% des contenants livrés en entreprises seront réemployables`}</strong>{` `}</li>
        </ul>
      </li>
    </ul>
    <p>{`Alors ça vous parle ? `}</p>
    <h3>{`Regardons de plus près les solutions proposées, indispensables pour un avenir plus durable!`}</h3>
    <p>{`Quelles sont-elles ? Suivons les `}<strong parentName="p">{`3R`}</strong>{` !`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Réduction :`}</strong>{` `}</li>
    </ul>
    <p>{`Anti-gaspi`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`2025: réduction de 50% du gaspillage alimentaire par rapport à 2015`}</strong>{` dans les domaines de la distribution et de la restauration collective`}</li>
      <li parentName="ul">{`2030: réduction de 50% du gaspillage alimentaire par rapport à 2015 au niveau de la production et de la transformation alimentaire`}</li>
    </ul>
    <p>{`Développement de la vente en vrac`}</p>
    <ul>
      <li parentName="ul">{`Consacrer 20% de la surface de vente à la vente en vrac d’ici 2030`}</li>
    </ul>
    <p>{`☝ A savoir : les dates de péremption sont responsables de 20% du gaspillage alimentaire!`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Réemploi :`}</strong>{` `}</p>
        <ul parentName="li">
          <li parentName="ul">{`2023: 5% des emballages seront réemployés`}</li>
          <li parentName="ul">{`2027: 10% des emballages seront réemployés`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Recyclage :`}</strong>{` `}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`2025: 100% des emballages plastiques mis sur le marché devront être recyclés`}</strong></li>
        </ul>
      </li>
    </ul>
    <p>{`Ces solutions pourraient sembler anodines mais ont en réalité un impact colossal sur 3 secteurs : l’environnement, l’emploi et l’économie.`}</p>
    <h3><strong parentName="h3">{`Mais quels sont ces effets?`}</strong>{` `}</h3>
    <ul>
      <li parentName="ul">{`Sur `}<strong parentName="li">{`l’environnement`}</strong>{` : effets positifs sur la préservation de la biodiversité et la réduction des émissions de gaz à effet de serre ( par exemple : produire une bouteille à partir de matière recyclée permet de réduire de 70% l’émission de gaz à effet de serre par rapport à la production d’une bouteille à partir de matière première nouvelle)`}</li>
      <li parentName="ul">{`Sur `}<strong parentName="li">{`l’emploi`}</strong>{` : `}<strong parentName="li">{`création de 300 000 emplois supplémentaires`}</strong>{` (1 tonne de déchets recyclés permet de créer 10 fois plus d’emploi qu’1 tonne de déchets enterrés)`}</li>
      <li parentName="ul">{`Sur `}<strong parentName="li">{`l’économie`}</strong>{` : diminution de la dépendance des importations de matériaux à l’étranger entraînant une amélioration de la production française de meilleure qualité`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      